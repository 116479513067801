
        @use 'sass:list';
        @use 'sass:map';
        @use "sass:math";

        
.component {
    $p: &;

    path,
    circle {
        vector-effect: non-scaling-stroke;
    }
}
