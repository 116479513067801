
        @use 'sass:list';
        @use 'sass:map';
        @use "sass:math";

        
.lightning {
    animation: lightning 1.75s ease-in-out infinite;

    @keyframes lightning {
        0%,
        20%,
        40%,
        100% {
            transform: none;
        }

        5% {
            transform: translate3d(4px, -4px, 0) skew(4deg, 4deg);
        }

        15% {
            transform: translate3d(-4px, 4px, 0) skew(-4deg, -4deg);
        }

        25% {
            transform: translate3d(2px, -2px, 0) skew(2deg, 2deg);
        }

        35% {
            transform: translate3d(-2px, 2px, 0) skew(-2deg, -2deg);
        }
    }
}
