
        @use 'sass:list';
        @use 'sass:map';
        @use "sass:math";

        
.section {
    scroll-margin-top: var(--scroll-offset, 8px);

    @screen md {
        scroll-margin-top: var(--scroll-offset, 16px);
    }

    @screen lg {
        scroll-margin-top: var(--scroll-offset, 12px);
    }
}

.cursor {
    &:nth-child(1) {
        top: 79px;
        left: 19px;

        @screen lg {
            top: 50%;
            left: 50%;
            margin-top: -280px;
            margin-left: -460px;
        }

        @screen xl {
            margin-left: -588px;
        }
    }

    &:nth-child(2) {
        top: 21px;
        right: 96px;
        left: auto;

        @screen lg {
            top: 50%;
            right: auto;
            left: 50%;
            margin-top: -324px;
            margin-left: 320px;
        }

        @screen xl {
            margin-left: 476px;
        }
    }

    &:nth-child(3) {
        top: auto;
        bottom: 130px;
        left: 9px;

        @screen lg {
            top: 50%;
            bottom: auto;
            left: 50%;
            margin-top: 211px;
            margin-left: -417px;
        }
    }

    &:nth-child(4) {
        top: auto;
        right: 133px;
        bottom: 72px;
        left: auto;

        @screen lg {
            top: 50%;
            right: auto;
            bottom: auto;
            left: 50%;
            margin-top: 145px;
            margin-left: 282px;
        }
    }
}
