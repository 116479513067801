
        @use 'sass:list';
        @use 'sass:map';
        @use "sass:math";

        
.component {
    $p: &;

    :global(.swiper-wrapper) {
        box-sizing: inherit;
    }

    &:global(.swiper-css-mode) :global(.swiper-wrapper) {
        overflow-y: hidden;
    }
}
